<style lang="scss" scoped>
.child-detail {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  .main {
    width: 920px;
    height: 100%;
    background: white;
    overflow-y: auto;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid $grow-min;

      .el-icon-close {
        cursor: pointer;
        font-size: 24px;
        // border-bottom: 1px solid $grow-min;
      }
    }

    .footer {
      display: flex;
      padding-top: 12px;
      border-top: 1px solid $grow-min;
      justify-content: center;
      margin-top: 12px;
    }

    .content {
      flex-grow: 1;
      overflow: hidden;
    }
  }
}
</style>

<style lang="scss">
.content {
  display: flex;
  flex-direction: column;

  .el-tab-pane {
    overflow-y: auto;
  }
}
</style>

<style lang="scss" scoped>
.content {
  .input-item {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .input-item-title {
      width: 100px;
      font-size: 14px;
    }
  }

  .custom-tablei1 {
    width: 900px;
    margin-top: 24px;

    .list-item {
      width: 100%;
      display: flex;
      margin-bottom: 12px;

      .row {
        flex-grow: 1;
        font-size: 14px;
        border-bottom: 1px solid $grow-min;
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }
  }
}

.contract-list-item {
  width: 900px;
  padding: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  // background: $grow-min;
  background: rgb(238, 238, 238);
  border-radius: 4px;

  .contrac-list-header {
    padding-bottom: 1px solid $white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .remove {
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="child-detail">
    <div class="main">
      <!-- {{ detailData }} -->
      <div class="header">
        <div>业务项目详情</div>
        <div class="el-icon-close" @click="close"></div>
      </div>
      <div class="content">
        <el-tabs v-model="activeName">
          <el-tab-pane label="申报费" name="first">
            <statistical-all :statistical="CtotalData1"></statistical-all>

            <div class="input-item">
              <div class="input-item-title">费用记录：</div>
              <div class="input-item-main">
                <el-button type="" @click="addDeclareAffix1"
                  >创建申报费记录</el-button
                >
              </div>
            </div>

            <div class="custom-tablei1">
              <div class="list-item">
                <div class="row">付款金额</div>
                <div class="row">付款日期</div>
                <div class="row">操作</div>
              </div>
              <div
                class="list-item"
                v-for="(item, index) of declareAffix1"
                :key="'travelExpense' + index"
              >
                <div class="row">
                  <el-input-number
                    v-model="item.payedMoney"
                    style="width:200px"
                  ></el-input-number>
                </div>
                <div class="row">
                  <el-date-picker
                    placement="bottom-start"
                    v-model="item.payedDate"
                    style="width:200px"
                  ></el-date-picker>
                </div>
                <div class="row">
                  <!-- <el-input
                    v-model="item.detail"
                    style="width:200px" -->
                  <!-- ></el-input> -->
                </div>
                <div class="row">
                  <el-button
                    @click="removeDeclareAffix1(item)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="采购费" name="second">
            <statistical-all :statistical="CtotalData2"></statistical-all>

            <div class="input-item">
              <div class="input-item-title">费用记录：</div>
              <div class="input-item-main">
                <el-button type="" @click="addDeclareAffix2"
                  >创建采购费记录</el-button
                >
              </div>
            </div>

            <div class="custom-tablei1">
              <div class="list-item">
                <div class="row">付款金额</div>
                <div class="row">付款日期</div>
                <div class="row">操作</div>
              </div>
              <div
                class="list-item"
                v-for="(item, index) of declareAffix2"
                :key="'travelExpense' + index"
              >
                <div class="row">
                  <el-input-number
                    v-model="item.payedMoney"
                    style="width:200px"
                  ></el-input-number>
                </div>
                <div class="row">
                  <el-date-picker
                    placement="bottom-start"
                    v-model="item.payedDate"
                    style="width:200px"
                  ></el-date-picker>
                </div>
                <div class="row">
                  <!-- <el-input
                    v-model="item.detail"
                    style="width:200px" -->
                  <!-- ></el-input> -->
                </div>
                <div class="row">
                  <el-button
                    @click="removeDeclareAffix2(item)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="补贴费" name="fifth">
            <statistical-all :statistical="CtotalData5"></statistical-all>

            <div class="input-item">
              <div class="input-item-title">费用记录：</div>
              <div class="input-item-main">
                <el-button type="" @click="addSubsidy"
                  >创建补贴费记录</el-button
                >
              </div>
            </div>

            <div class="custom-tablei1">
              <div class="list-item">
                <div class="row">收款金额</div>
                <div class="row">收款日期</div>
                <div class="row">操作</div>
              </div>
              <div
                class="list-item"
                v-for="(item, index) of subsidys"
                :key="'travelExpense' + index"
              >
                <div class="row">
                  <el-input-number
                    v-model="item.receivedMoney"
                    style="width:200px"
                  ></el-input-number>
                </div>
                <div class="row">
                  <el-date-picker
                    placement="bottom-start"
                    v-model="item.receivedDate"
                    style="width:200px"
                  ></el-date-picker>
                </div>
                <div class="row">
                  <!-- <el-input
                    v-model="item.detail"
                    style="width:200px" -->
                  <!-- ></el-input> -->
                </div>
                <div class="row">
                  <el-button
                    @click="deleteSubsidy(item)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 差旅费 -->
          <el-tab-pane label="差旅费" name="third">
            <statistical-all :statistical="CtotalData3"></statistical-all>

            <div class="input-item">
              <div class="input-item-title">费用记录：</div>
              <div class="input-item-main">
                <el-button type="" @click="addTravelExpense"
                  >创建差旅费记录</el-button
                >
              </div>
            </div>

            <div class="custom-tablei1">
              <div class="list-item">
                <div class="row">付款金额</div>
                <div class="row">付款日期</div>
                <div class="row">明细</div>
              </div>
              <div
                class="list-item"
                v-for="(item, index) of travelExpense"
                :key="'travelExpense' + index"
              >
                <div class="row">
                  <el-input-number
                    v-model="item.payAmount"
                    style="width:200px"
                  ></el-input-number>
                </div>
                <div class="row">
                  <el-date-picker
                    placement="bottom-start"
                    v-model="item.payDate"
                    style="width:200px"
                  ></el-date-picker>
                </div>
                <div class="row">
                  <el-input
                    v-model="item.detail"
                    style="width:200px"
                  ></el-input>
                </div>
                <div class="row">
                  <el-button
                    @click="deleteTravelExpense(item)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 业务招待费 -->
          <el-tab-pane label="业务招待费" name="fourth">
            <statistical-all :statistical="CtotalData4"></statistical-all>
            <div class="input-item">
              <div class="input-item-title">费用记录：</div>
              <div class="input-item-main">
                <el-button type="" @click="addBusinessEntertainment"
                  >创建差旅费记录</el-button
                >
              </div>
            </div>

            <div class="custom-tablei1">
              <div class="list-item">
                <div class="row">付款金额</div>
                <div class="row">付款日期</div>
                <div class="row">明细</div>
              </div>
              <div
                class="list-item"
                v-for="(item, index) of businessEntertainment"
                :key="'businessEntertainmentDefault' + index"
              >
                <div class="row">
                  <el-input-number
                    v-model="item.payAmount"
                    style="width:200px"
                  ></el-input-number>
                </div>
                <div class="row">
                  <el-date-picker
                    placement="bottom-start"
                    v-model="item.payDate"
                    style="width:200px"
                  ></el-date-picker>
                </div>
                <div class="row">
                  <el-input
                    v-model="item.detail"
                    style="width:200px"
                  ></el-input>
                </div>
                <div class="row">
                  <el-button
                    @click="removeBusinessEntertainment(item)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="footer">
        <el-button type="primary" @click="commit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  addDeclareAffix,
  getDeclareAffix,
  deleteDeclareAffix,
  updateDeclareAffix,
  deleteSubsidy,
  getSubsidy,
  updateSubsidy,
  addSubsidy,
  update

} from "@/api/declare";
import {
  getBusinessEntertainment,
  updateBusinessEntertainment,
  addBusinessEntertainment,
  deleteBusinessEntertainment,
  getTravelExpense,
  addTravelExpense,
  updateTravelExpense,
  deleteTravelExpense,
  addSalesContract,
  updateSalesContract,
  deleteSalesContract,
  getSalesContract,
  getBusinessAffix,
  updateBusinessAffix,
  addBusinessAffix,
  getPurchaseContract,
  addPurchaseContract,
  updatePurchaseContract,
  deletePurchaseContract,
} from "../../../../api/project";

//默认差旅费，业务招待费数据
const defaultData1 = (type) => {
  return {
    type,
    payedMoney: 0,
    payedDate: null,
    detail: "",
  };
};

//默认差旅费，业务招待费数据
const businessEntertainmentDefault = () => {
  return {
    payAmount: 0,
    payDate: null,
    detail: "",
  };
};

//默认补贴费
const receivedDefault = () => {
  return {
    receivedMoney: 0,
    receivedDate: null,
  };
};

export default {
  props: {
    detailData: Object,
  },
  data() {
    return {
      activeName: "first",
      declareId: null,
      declareAffix1: [],
      declareAffix2: [],
      businessEntertainment: [],
      travelExpense: [],
      subsidys: [],
    };
  },
  created() {
    this.declareId = this.detailData.id;
    this.initData();
  },
  computed: {
    CtotalData1() {
      let val = 0;
      this.declareAffix1.map((e) => {
        val += parseFloat(e.payedMoney);
      });
      return [
        // {
        //   name: "应付总额",
        //   value: this.detailData.declarationFee,
        // },
        {
          name: "已付总额",
          value: val,
        },
      ];
    },
    CtotalData2() {
      let val = 0;
      this.declareAffix2.map((e) => {
        val += parseFloat(e.payedMoney);
      });
      return [
        // {
        //   name: "应付总额",
        //   value: this.detailData.purchaseFee,
        // },
        {
          name: "已付总额",
          value: val,
        },
      ];
    },

    CtotalData3() {
      let val = 0;
      this.travelExpense.map((e) => {
        val += parseFloat(e.payAmount);
      });
      return [
        // {
        //   name: "应付总额",
        //   value: this.detailData.travelFee,
        // },
        {
          name: "已付总额",
          value: val,
        },
      ];
    },
    CtotalData4() {
      let val = 0;
      this.businessEntertainment.map((e) => {
        val += parseFloat(e.payAmount);
      });
      return [
        // {
        //   name: "应付总额",
        //   value: this.detailData.businessEntertainmentFee,
        // },
        {
          name: "已付总额",
          value: val,
        },
      ];
    },
    CtotalData5() {
      let val = 0;
      this.subsidys.map((e) => {
        val += parseFloat(e.receivedMoney);
      });
      return [
        {
          name: "应收总额",
          value: this.detailData.subsidyMoney,
        },
        {
          name: "已收总额",
          value: val,
        },
      ];
    },
  },
  methods: {
    addTravelExpense() {
      this.travelExpense.push(businessEntertainmentDefault());
    },

    addSubsidy() {
      this.subsidys.push({
        declareId:this.declareId,
        ...receivedDefault(),
      });
    },
    async deleteSubsidy(item){
      if(item.id){
        await deleteSubsidy(item)
      }
      this.subsidys.splice(this.subsidys.indexOf(item),1)
      this.$message.success("删除成功")
    },
    async loadDeclareAffix1() {
      let data = await getDeclareAffix({
        declareId: this.declareId,
        type: 0,
      });
      this.declareAffix1 = data.data || [];
    },
    async loadDeclareAffix2() {
      let data = await getDeclareAffix({
        declareId: this.declareId,
        type: 1,
      });
      this.declareAffix2 = data.data || [];
    },
    async getSubsidy() {
      let data = await getSubsidy({
        declareId: this.declareId,
      });
      this.subsidys = data.data || [];
    },
    async addDeclareAffix1() {
      this.declareAffix1.push({
        ...defaultData1(0),
        declareId: this.declareId,
      });
    },
    async addBusinessEntertainment() {
      this.businessEntertainment.push(businessEntertainmentDefault());
    },
    async deleteTravelExpense(item) {
      if (item.id) {
        await deleteTravelExpense({ id: item.id });
        this.$parent.loadCustomData();
      }

      this.travelExpense.splice(this.travelExpense.indexOf(item), 1);
      this.$message.success("删除成功");
    },
    async removeDeclareAffix1(item) {
      if (item.id) {
        await deleteDeclareAffix(item);
      }
      this.declareAffix1.splice(this.declareAffix1.indexOf(item), 1);
      this.$message.success("删除成功");
    },
    async addDeclareAffix2() {
      this.declareAffix2.push({
        ...defaultData1(1),
        declareId: this.declareId,
      });
    },
    async removeDeclareAffix2(item) {
      if (item.id) {
        await deleteDeclareAffix(item);
      }
      this.declareAffix2.splice(this.declareAffix2.indexOf(item), 1);
      this.$message.success("删除成功");
    },
    async initData() {
      this.loadDeclareAffix1();
      this.loadDeclareAffix2();
      this.getSubsidy();
      await this.getBusinessEntertainment();
      await this.getTravelExpense();
    },
    async getBusinessEntertainment() {
      try {
        let data = await getBusinessEntertainment({
          declareId: this.declareId,
        });
        this.businessEntertainment = data.data || [];
        // this.bu/
      } catch (error) {
        this.businessEntertainment = [];
      }
    },
    async getTravelExpense() {
      try {
        let data = await getTravelExpense({
          declareId: this.declareId,
        });
        this.travelExpense = data.data || [];
        // this.bu/
      } catch (error) {
        this.travelExpense = [];
      }
    },
    close() {
      this.$parent.showDetail = false;
    },
    checks() {
      return ture;
    },
    async commits() {
      //申报费
      {
        for (let item of this.declareAffix1) {
          if (item.id) {
            await updateDeclareAffix(item);
          } else {
            await addDeclareAffix(item);
          }
        }
      }

      //采购费
      {
        for (let item of this.declareAffix2) {
          if (item.id) {
            await updateDeclareAffix(item);
          } else {
            await addDeclareAffix(item);
          }
        }
      }

      //补贴费
      {
        for(let item of this.subsidys){
          if(item.id){
            await updateSubsidy(item)
          }else{
            await addSubsidy(item)
          }
        }
      }

      //业务招待费
      {
        for (let item of this.businessEntertainment) {
          if (item.payDate) {
            item.payDate = moment(item.payDate).format("YYYY-MM-DD");
          }

          if (item.id) {
            await updateBusinessEntertainment(item);
          } else {
            await addBusinessEntertainment({
              ...item,
              declareId: this.declareId,
            });
          }
        }
      }

      //差旅费
      {
        for (let item of this.travelExpense) {
          if (item.payDate) {
            item.payDate = moment(item.payDate).format("YYYY-MM-DD");
          }

          if (item.id) {
            await updateTravelExpense(item);
          } else {
            await addTravelExpense({ declareId: this.declareId, ...item });
          }
        }   
      }

         {
        this.detailData.declarationFee=this.CtotalData1[0].value
        this.detailData.purchaseFee=this.CtotalData2[0].value
        // this.detailData.subsidyMoney=this.CtotalData3[0].value
        this.detailData.businessEntertainmentFee=this.CtotalData4[0].value
        await update({
          ...this.detailData,
        })
      }

    },
      async removeBusinessEntertainment(item) {
      if (item.id) {
        await deleteBusinessEntertainment({ id: item.id });
        this.$parent.loadCustomData();
      }

      this.businessEntertainment.splice(
        this.businessEntertainment.indexOf(item),
        1
      );
      this.$message.success("删除成功");
    },
    async commit() {
      if (!this.checks) {
        return;
      }

      await this.commits();

      this.$parent.loadCustomData();

      this.$message.success("提交成功！");

       this.$parent.showDetail = false;
    },
  },
};
</script>
