<template>
  <div class="contract-section">
    <custom-table
      ref="loadCustomData"
      :loadData="loadData"
      :remove="remove"
      @rowClick="rowClick"
      :drawers="drawers"
      :edit="edit"
      :pageInfo="pageInfo"
      :statistical="statistical"
      :filters="filters"
      :tableColumns="tableColumns"
    />
    <projectChild v-if="showDetail" :detailData="detailData"></projectChild>
  </div>
</template>

<script>
import projectChild from "./child.vue";
import { list, update, remove, add } from "@/api/declare.js";
export default {
  components: {
    projectChild,
  },
  data() {
    return {
      showDetail: false,
      filters: [
        {
          name: "项目名称",
          value: "",
          property: "projectName",
          defaultValue() {
            return "";
          },
          type: "input",
          clearable: true,
        },
      ],
      drawers: [
        {
          name: "项目名称",
          value: "",
          property: "projectName",
          defaultValue() {
            return "";
          },
          type: "input",
          require: true,
          clearable: true,
        },
        // {
        //   name: "申报费",
        //   property: "declarationFee",
        //   value: 0,
        //   type: "number",
        //   min: 0,
        //   require: true,
        //   defaultValue() {
        //     return 0;
        //   },
        //   formatCommit(e) {
        //     return e.toFixed(2);
        //   },
        //   clearable: true,
        // },
        // {
        //   name: "采购费",
        //   property: "purchaseFee",
        //   value: 0,
        //   type: "number",
        //   min: 0,
        //   require: true,
        //   defaultValue() {
        //     return 0;
        //   },
        //   formatCommit(e) {
        //     return e.toFixed(2);
        //   },
        //   clearable: true,
        // },
        {
          name: "补贴款",
          property: "subsidyMoney",
          value: 0,
          type: "number",
          min: 0,
          require: true,
          defaultValue() {
            return 0;
          },
          formatCommit(e) {
            return e.toFixed(2);
          },
          clearable: true,
        },
        // {
        //   name: "差旅费",
        //   property: "travelFee",
        //   value: 0,
        //   type: "number",
        //   min: 0,
        //   require: true,
        //   defaultValue() {
        //     return 0;
        //   },
        //   formatCommit(e) {
        //     return e.toFixed(2);
        //   },
        //   clearable: true,
        // },
        // {
        //   name: "业务招待费",
        //   property: "businessEntertainmentFee",
        //   value: 0,
        //   type: "number",
        //   min: 0,
        //   require: true,
        //   defaultValue() {
        //     return 0;
        //   },
        //   formatCommit(e) {
        //     return e.toFixed(2);
        //   },
        //   clearable: true,
        // },
      ],
      tableColumns: [
        {
          name: "项目名称",
          props: "projectName",
        },
        {
          name: "申报费",
          props: "declarationFee",
          isFormat: true,
          isFormat: true,
          format: (date) => {
            date = date || 0;

            return "￥" + date;
          },
        },
        {
          name: "采购费",
          props: "purchaseFee",
          isFormat: true,
          isFormat: true,
          format: (date) => {
            date = date || 0;

            return "￥" + date || 0;
          },
        },
        {
          name: "补贴款",
          props: "noHaveReceivedMoney",
          isFormat: true,
          isFormat: true,
          format: (date) => {
            date = date || 0;

            return "￥" + date || 0;
          },
        },
        {
          name: "差旅费",
          props: "travelFee",
          isFormat: true,
          isFormat: true,
          format: (date) => {
            date = date || 0;

            date = date || 0;
            return "￥" + date || 0;
          },
        },
        {
          name: "业务招待费",
          props: "businessEntertainmentFee",
          isFormat: true,
          isFormat: true,
          format: (date) => {
            date = date || 0;
            return "￥" + date || 0;
          },
        },
        {
          name: "操作",
          isEdit: true,
          edits: ["remove", "edit"],
        },
      ],
      pageInfo: {
        pageSize: 10,
        pageNumber: 1,
        total: 1000,
      },
      totalData: {},
      //详细信息
      detailData: {},
    };
  },
  computed: {
    statistical() {
      let data = [
        {
          name: "应收总额",
          value: this.totalData.shouldReceivedTotal || 0,
        },
        {
          name: "已收总额",
          value: this.totalData.haveReceivedTotal || 0,
        },
        {
          name: "未收总额",
          value: this.totalData.noHaveReceivedTotal || 0,
        },
        {
          name: "支出总额",
          value: this.totalData.payedTotal || 0,
        },
      ];
      return data;
    },
  },
  methods: {
    loadCustomData() {
      this.$refs["loadCustomData"].loadDatas();
    },
    rowClick(data) {
      this.showDetail = true;
      this.detailData = data;
    },
    async loadData(data) {
      let res = await list(data);
      this.totalData = res.data;
      return {
        list: res.data.basicPageVo.list,
        total: res.data.basicPageVo.total,
      };
    },
    async remove(row) {
      await remove({
        id: row.id,
      });
    },
    async edit(data) {
      let httpMethod = data.drawerType == "edit" ? update : add;
      delete data["drawerType"];
      await httpMethod(data);
    },
  },
};
</script>
