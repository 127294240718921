import axios from "@/lib/axios";

export const add = (data) => {
  return axios.request({
    url: "/declare/addDeclare",
    data,
    method: "post",
  });
};

export const update = (data) => {
  return axios.request({
    url: "/declare/updateDeclare",
    data,
    method: "post",
  });
};

export const list = (params) => {
  return axios.request({
    url: "/declare/getDeclare",
    params,
  });
};

export const remove = (params) => {
  return axios.request({
    url: "/declare/deleteDeclare",
    params,
  });
};

export const addDeclareAffix = (data) => {
  return axios.request({
    url: "/declare/addDeclareAffix",
    data,
    method: "post",
  });
};

export const getDeclareAffix = (params) => {
  return axios.request({
    url: "/declare/getDeclareAffix",
    params,
  });
};

export const deleteDeclareAffix = (params) => {
  return axios.request({
    url: "/declare/deleteDeclareAffix",
    params,
  });
};

export const updateDeclareAffix = (data) => {
  return axios.request({
    url: "/declare/updateDeclareAffix",
    data,
    method: "post",
  });
};

export const addSubsidy = (data) => {
  return axios.request({
    url: "/declare/addSubsidy",
    method: "post",
    data,
  });
};

export const updateSubsidy = (data) => {
  return axios.request({
    url: "/declare/updateSubsidy",
    method: "post",
    data,
  });
};

export const getSubsidy = (params) => {
  return axios.request({
    url: "/declare/getSubsidy",
    params,
  });
};

export const deleteSubsidy = (params) => {
  return axios.request({
    url: "/declare/deleteSubsidy",
    params,
  });
};
