import axios from "@/lib/axios";

export const list = (params) => {
  return axios.request({
    url: "business/getBusiness",
    params,
  });
};

export const add = (data) => {
  return axios.request({
    url: "business/addBusiness",
    data,
    method: "post",
  });
};

export const remove = ({ id }) => {
  return axios.request({
    url: "business/deleteBusiness",
    params: {
      id,
    },
  });
};

export const update = (data) => {
  return axios.request({
    url: "business/updateBusiness",
    data,
    method: "post",
  });
};

export const getBusinessEntertainment = (params) => {
  return axios.request({
    url: "business/getBusinessEntertainment",
    params,
  });
};

export const addBusinessEntertainment = (data) => {
  return axios.request({
    url: "business/addBusinessEntertainment",
    method: "post",
    data,
  });
};

export const updateBusinessEntertainment = (data) => {
  return axios.request({
    url: "business/updateBusinessEntertainment",
    method: "post",
    data,
  });
};

export const deleteBusinessEntertainment = (params) => {
  return axios.request({
    url: "business/deleteBusinessEntertainment",
    params,
  });
};
export const getTravelExpense = (params) => {
  return axios.request({
    url: "business/getTravelExpense",
    params,
  });
};

export const addTravelExpense = (data) => {
  return axios.request({
    url: "business/addTravelExpense",
    method: "post",
    data,
  });
};

export const updateTravelExpense = (data) => {
  return axios.request({
    url: "business/updateTravelExpense",
    method: "post",
    data,
  });
};

export const deleteTravelExpense = (params) => {
  return axios.request({
    url: "business/deleteTravelExpense",
    params,
  });
};

export const addSalesContract = (data) => {
  return axios.request({
    url: "business/addSalesContract",
    data,
    method: "post",
  });
};
export const getSalesContract = (params) => {
  return axios.request({
    url: "business/getSalesContract",
    params,
  });
};

export const updateSalesContract = (data) => {
  return axios.request({
    url: "business/updateSalesContract",
    data,
    method: "post",
  });
};

export const deleteSalesContract = (params) => {
  return axios.request({
    url: "business/deletaSalesContract",
    params,
  });
};

export const getBusinessAffix = (params) => {
  return axios.request({
    url: "business/getBusinessAffix",
    params,
  });
};

export const addBusinessAffix = (data) => {
  return axios.request({
    url: "business/addBusinessAffix",
    data,
    method: "post",
  });
};

export const updateBusinessAffix = (data) => {
  return axios.request({
    url: "business/updateBusinessAffix",
    data,
    method: "post",
  });
};

export const getPurchaseContract = (params) => {
  return axios.request({
    url: "business/getPurchaseContract",
    params,
  });
};

export const addPurchaseContract = (data) => {
  return axios.request({
    url: "business/addPurchaseContract",
    data,
    method: "post",
  });
};
export const updatePurchaseContract = (data) => {
  return axios.request({
    url: "business/updatePurchaseContract",
    data,
    method: "post",
  });
};

export const deletePurchaseContract = (params) => {
  return axios.request({
    url: "business/deletePurchaseContract",
    params,
  });
};
